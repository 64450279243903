import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import PageRoot from "../components/PageRoot"
import AtsGrid from "../components/AtsGrid"
import { devLog } from "../utils"
import ContainerWithPadding from "../components/ContainerWithPadding"
import { Empty } from "antd"
import BundleTile from "../components/BundleTile"
import withTileBadgeCheck from "../components/withTileBadgeCheck"

const AllBundlesPage = ({ location, data, navigate, navigation }) => {
  const { t } = useTranslation()
  const bundles = data.ats.bundles

  devLog({ bundles })

  const renderItem = (item, index) => {
    const { seo } = item

    const TileComponent = withTileBadgeCheck(BundleTile)

    return (
      <TileComponent
        data={item}
        type="full"
        onClick={() => {
          navigate(`/bundle/${seo.slug}`)
        }}
      />
    )
  }

  return (
    <PageRoot title={t("label:bundles")} showTitle>
      <ContainerWithPadding>
        <AtsGrid
          colSettings={{ xs: 24, sm: 12, md: 6, lg: 6 }}
          dataSource={bundles}
          emptyItem={<Empty description={t("label:noBundlesAvailable")} />}
          renderItem={renderItem}
        />
      </ContainerWithPadding>
    </PageRoot>
  )
}

export const query = graphql`
  query allBundles {
    ats {
      bundles(
        order_by: { title: asc }
        where: { state: { _eq: "PUBLISHED" } }
      ) {
        ...PreviewCardBundle
      }
    }
  }
`

export default AllBundlesPage
